import React from 'react'

import Layout from '~components/Layout'
import SEO from '~components/seo'

import RepairIntro from './RepairIntro'

function RepairWizard () {
  return (
    <Layout>
      <SEO
        title="Start a Online Repair in 3 easy steps with our Repair Wizard"
        description="Our Repair Wizard uses proprietary technology to generate repair estimates, set up on-demand pick-ups, and create shipping labels."
        pathname="/start-a-repair"
      />
      <RepairIntro />
    </Layout>
  )
}

export default RepairWizard
